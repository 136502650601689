import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Todo.module.css";
import "./index.css";
import { Hanko, register } from "@teamhanko/hanko-elements";
import { InviteCom } from "./GradientButton";
import { cookieUtilGet, useCharmingCookie } from "./util";

const api = process.env.REACT_APP_HANKO_API!;
var inviteCode: string | any

function HankoAuth() {

  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const hankoAPI = useMemo(() => new Hanko(api), []);
  const { charmingCookie, updateCookie } = useCharmingCookie();
  // const hankoAPI = new Hanko(api)

  // const redirectToTodos = useCallback(() => {
  //   navigate("/todo", { replace: true });
  // }, [navigate]);

  const redirectToTodos = useCallback(async () => {
    const user:any = await hankoAPI.user.getCurrent()

    const cookie = cookieUtilGet(hankoAPI, charmingCookie);
    console.log(`cookie:${JSON.stringify(cookie)}`);
    console.log(`user:${JSON.stringify(user)}`);

    let userString = JSON.stringify(user)
    const urlScheme = `charming://login?user=${userString}&token=${cookie}&inviter=${inviteCode}`;

    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) return;
      console.log("❌不可以打开App");
      // request a post API with fetch
      fetch('https://api.charming.chat/createUser', { //TODO: change to https://api.charming.chat    http://127.0.0.1:8787
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${cookie}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.email,
          invite_id: inviteCode
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log(`我也不知道啊这是什么：${JSON.stringify(data)}}`)
        // data.code
        window.location.href = "https://apps.apple.com/cn/app/charming-%E8%8E%AB%E6%96%AF/id6446066593";
      })
      .catch(error => console.error(error))

    }, 50);
    window.location.href = urlScheme;
    console.log(`✅ 准备打开App:${urlScheme}`);

    var pr = "/profile"
    if (inviteCode != null && inviteCode != "") {
      pr = "/profile?code=" + inviteCode
    }
    navigate(pr, { replace: true });
   

  }, [navigate]);

  // 进入页面必展示
  useEffect(()=>{
    onlod()
  }, [])

  useEffect(() => {
    register(api, { shadow: true }).catch(setError);
  }, []);

  useEffect(
    () => hankoAPI.onSessionCreated(() => redirectToTodos()),
    [hankoAPI, redirectToTodos]
  );

  // useEffect(() => {
  //   register({ shadow: true }).catch(setError);
  //   document.addEventListener("hankoAuthSuccess", redirectToTodos);
  //   return () =>
  //     document?.removeEventListener("hankoAuthSuccess", redirectToTodos);
  // }, [redirectToTodos, setError]);

  const onlod = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(`code: ${code}`); // Outputs "1762H" if the URL is https://login.charming.chat?code=1762H
    inviteCode = code
  }

  return (
    // <div className={styles.content}>
    //   <div className={styles.error}>{error?.message}</div>
    //     <hanko-auth api={api} />
    // </div>

      <div>
        <div className={styles.content}>
          <div className={styles.error}>{error?.message}</div>
          <hanko-auth/>
      </div>
      <InviteCom/>
    </div>
  );
}

export default HankoAuth;
