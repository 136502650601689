// useCookie.ts
import { Hanko } from '@teamhanko/hanko-elements';
import { useState, useEffect } from 'react';

function parseQueryString(queryString: string) {
  const params = new URLSearchParams(queryString);
  return Object.fromEntries(params.entries());
}

export function useCharmingCookie() {
  const [charmingCookie, setCookie] = useState('');

  useEffect(() => {
    const currentURL = window.location.search;
    console.log(`当前页面url：${currentURL}`)
    const urlParams = parseQueryString(currentURL);
    console.log(`读取url 参数：${JSON.stringify(urlParams)}`)
    if ('token' in urlParams && urlParams.token !== '') {
      console.log(`读取到 url cookie 参数：${urlParams.token}`)
      setCookie(urlParams.token);
    }
  }, []); // 只在组件挂载时运行一次

  return {
    charmingCookie,
    updateCookie: (newCookie: string) => {
      if (newCookie !== '') {
        setCookie(newCookie);
      }
    },
  };
}

export const cookieUtilGet = (hankoAPI: Hanko, defaultCookie: string | null): string => {
  // 优先选择链接中的 cookie
    var cookie = defaultCookie;

    if ((cookie == null || cookie == "")) {
      cookie = hankoAPI.token.client.cookie.getAuthCookie()
      console.log(`cookie in Hanko:${JSON.stringify(cookie)}`);
    } else {
      console.log(`使用链接中的 cookie:${JSON.stringify(cookie)}`);
    }
    cookie = cookie.split('?token=')[0];
    console.log(`最终cookie:${cookie}`);
    return cookie
  }