import React from 'react';
import './LogoFooter.css';
import logo from './logo192.png';


function LogoFooter() {

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const logout = () => {
    openInNewTab("https://charming.chat")
  };
  
  return (
    <div className="logo-footer" onClick={logout}>
      <img src={logo} alt="logo" className="logo-image" />
      <div className="text-container">
        <h3 className="charming-text">Charming</h3>
        <h4 className="mos-text">莫斯</h4>
      </div>
    </div>
  );
}

export default LogoFooter;