import React, { useEffect, useMemo, useState } from "react";
// import { Hanko, register } from "./elements/src";
import { Hanko, User, register } from "@teamhanko/hanko-elements";
import styles from "./Todo.module.css";
import { useNavigate } from "react-router-dom";
// import { Hanko, User, UserClient } from "@teamhanko/hanko-frontend-sdk"
import ReactMarkdown from "react-markdown";
import GradientButton, { InviteCom, isInBlacklist } from "./GradientButton";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { cookieUtilGet, useCharmingCookie } from "./util";

const api = process.env.REACT_APP_HANKO_API!
const hankoAPI = new Hanko(api)

var inviteCode: string | any
var myCode: string | any

// console.log = function () {};

function HankoProfile() {
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const { charmingCookie, updateCookie } = useCharmingCookie();

  const copyText = async (text:string) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
  };
  
  function showCopiedToast() {
    if (myCode && myCode != "") {
      let copy = `https://login.charming.chat/?code=${myCode}`
      copyText(copy).then(() => {
        toast.success('复制成功，快去分享给好友吧！'); 
      })
    } else {
      toast.error('暂时无法获取邀请链接，请稍后再试！'); 
    }
  }
  
  const handleButtonClick = () => {
    // hankoAPI.user.
    // var copy = "https://login.charming.chat?code=" + myCode
  
    if (myCode == null || myCode == "") {
      var loadinID = toast.loading('生成链接中...');
      // success('复制成功，快去分享给好友吧！'); 
      hankoAPI.user.getCurrent().then((user:any) => {
        const cookie = cookieUtilGet(hankoAPI, charmingCookie);
        console.log(`profile cookie:${JSON.stringify(cookie)}`);
        console.log(`user:${JSON.stringify(user)}`);
  
        fetch('https://api.charming.chat/user', { //TODO: change to https://api.charming.chat    http://127.0.0.1:8787
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${cookie}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(data => {
          toast.dismiss(loadinID)
          console.log("获取到用户信息:", data)
          // data.code
          myCode = data.data.code
          showCopiedToast()
        })
        .catch(err1 => {
          toast.dismiss(loadinID)
          toast.error(err1.message)
        });
      }).catch((err:any) => {
        toast.error(err.message);
      })
    } else {
      showCopiedToast()
    }
  };

  const logout = () => {
    hankoAPI.user.logout().then( ()=>{
      const urlScheme = `charming://logout`;
      window.location.href = urlScheme;

      var pr = "/"
      if (inviteCode != null && inviteCode != "") {
        pr = "/?code=" + inviteCode
      }
      navigate(pr);
    })
  };

  const pay = () => {
    var pr = "/pay"
    navigate(pr);
  };

  useEffect(() => {
    register(api, { shadow: true }).catch(setError);
  }, []);

  // 进入页面必展示
  useEffect(()=>{
    onlod()
  }, [])

  // 如果没有登录，不显示下面的购买页面，而是现实一个大的 logo，并配有文字请登录
  const checkUser = async () => {
    const _user = await hankoAPI.user.getCurrent()
    console.log(`检查user:${JSON.stringify(_user)}`);
    setUser(_user);
  }
  
  const onlod = async () => {
    await checkUser()
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(`code: ${code}`); // Outputs "1762H" if the URL is https://login.charming.chat?code=1762H
    inviteCode = code
  }

  return (
    <>
      <nav className={styles.nav}>
        <button onClick={logout} className={styles.button}>
          退出登录
        </button>
        <button disabled className={styles.button}>
          用户中心
        </button>
        {(!isInBlacklist(user)) && (
        <button onClick={pay} className={styles.button}> 
          Token 购买 
        </button>
        )}
      </nav>
      <div className={styles.content}>
        <h1 className={styles.headline}>Profile</h1>
        <div className={styles.error}>{error?.message}</div>
        <hanko-profile/>
      </div>
      <InviteCom/>
      <div className="content">
        <GradientButton onClick={handleButtonClick} />
        <ToastContainer />
      </div>
    </>
  );
}

export default HankoProfile;
