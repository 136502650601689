import React, { useState, useEffect, useMemo } from 'react';
import { Button, message, Spin, Card } from 'antd';
import axios from 'axios';
import { Hanko, User, register } from "@teamhanko/hanko-elements";
import styles from "./Todo.module.css";
import { useNavigate } from "react-router-dom";
import Confetti from 'react-confetti';
import { AlipayCircleFilled } from '@ant-design/icons';
import queryString from 'query-string';
import { isInBlacklist } from './GradientButton';
import { cookieUtilGet, useCharmingCookie } from './util';

const API = {
  // createOrder: 'http://127.0.0.1:8787/createOrder12/alipay',
  createOrder: 'https://api.charming.chat/createOrder12/alipay',
  create60Order: 'https://api.charming.chat/createOrder60/alipay',
  // createSubOrder: 'http://127.0.0.1:8787/createSubOrder/alipay',
  createSubOrder: 'https://api.charming.chat/createSubOrder/alipay',
  // queryOrder: 'http://127.0.0.1:8787/queryOrder',// TODO: change to https://api.charming.chat
  queryOrder: 'https://api.charming.chat/queryOrder'
};

const api = process.env.REACT_APP_HANKO_API!;

const CharmingPay = () => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [orderId, setOrderId] = useState('');
  const [paid, setPaid] = useState(false);
  const [polling, setPolling] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { charmingCookie, updateCookie } = useCharmingCookie();


  useEffect(() => {
    if (polling) {
      queryOrder();
    }
  }, [polling]);
  
  // 在组件卸载时清除计时器
  useEffect(() => {
    const fetchData = async () => {
      await checkUser();
    };
    fetchData();

    return () => {
      setPolling(false);
    };
  }, []);
  
  // 如果没有登录，不显示下面的购买页面，而是现实一个大的 logo，并配有文字请登录
  const checkUser = async () => {
    const _user = await hankoAPI.user.getCurrent()
    setUser(_user);
  }

  const profile = () => {
    var pr = "/profile"
    setPolling(false);
    navigate(pr);
  };

  const hankoAPI = useMemo(() => new Hanko(api), []);

  // // 处理二维码链接
  const handleQrCodeClick = async () => {
    await handleJumpToAlipay();
  };


  // 创建订单
  const createOrder = async (api: string) => {
    setLoading(true);

    try {
      const cookie = cookieUtilGet(hankoAPI, charmingCookie);
      console.log(`订单cookie：${cookie}`);
      console.log(`订单api：${api}`);
      const res = await axios.post(`${api}?token=${cookie}`);

      // 生成二维码图片
      setQrCode(res.data.info.qr);
      setOrderId(res.data.aoid);
      setLoading(false);
      setPolling(true);
      console.log(`orderId:${orderId}`);
    } catch (err) {
      setLoading(false);
      console.log(`订单报错：${err}`);
      message.error(`创建订单失败，请登录`);
    }
  };

  const requestQueryOrder = async () => {
    try {
      const cookie = cookieUtilGet(hankoAPI, charmingCookie);
      const res = await axios.get(`${API.queryOrder}?token=${cookie}&orderId=${orderId}`);
      const status = res.data.status;
      return status;
    } catch (err) {
      message.error('查询订单状态失败');
    }
  }

  // 轮询订单状态
  const queryOrder = async () => {
    const status = await requestQueryOrder()
    if (status === 'success') {
      message.success('🎉 购买成功 🎉');
      setPaid(true);
      setPolling(false);
      setConfetti(true);
      setTimeout(() => {
        setConfetti(false);
        setPaid(false);
        setOrderId('');
        setQrCode('');
      }, 6000);
    } else if (status === 'not_exist' || status === 'expire') {
      message.error('订单已过期或不存在');
      setPolling(false);
    } else {
      if (polling) {
        setTimeout(queryOrder, 500);
      }
    }
  };

    // 点击跳转支付宝
    const handleJumpToAlipay = async () => {
      if (qrCode) {
        window.open(qrCode, '_blank');
      }
    };

  // 点击我已付款按钮
  const handlePaid = async () => {
    try {
      const status = await requestQueryOrder()
      message.info(`当前订单状态：${status}`);
    } catch (err) {
      message.error('查询订单状态失败');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <nav className={styles.nav}>
      {(!isInBlacklist(user)) ? 
        <button disabled className={styles.button}> Token 购买 </button> : 
        <button disabled className={styles.button}> 请登录 </button>
      }
        <button onClick={profile} className={styles.button}>
          用户中心
        </button>
      </nav>
      {(!isInBlacklist(user)) ? 
        <h1>Token 购买页面</h1> : 
        <h1>请先登录哦</h1>
      }
      {confetti && <Confetti />}
      {!qrCode && !paid && (!isInBlacklist(user)) &&(
        <Card
          hoverable
          style={{
            width: 300,
            borderRadius: 10,
            margin: '100px auto',
            background: 'linear-gradient(to right, #4E5673, #0B2E42)',
            fontWeight: 'bold',
          }}
          bodyStyle={{ padding: 20 }}
          onClick={() => createOrder(API.createOrder)}
          loading={loading}
        >
          <div className="gradient-text">120,000 Token</div>
          <div className="gradient-text" style={{ fontSize: 30, marginTop: 10 }}>
            ￥6.00
          </div>
          <div style={{ marginTop: 10, color: "white" }}>
            <AlipayCircleFilled style={{ fontSize: 24 }} />
          </div>
      </Card>
      )}
      {!qrCode && !paid && (!isInBlacklist(user)) &&(
        <Card
          hoverable
          style={{
            width: 300,
            borderRadius: 10,
            margin: '100px auto',
            background: 'linear-gradient(to right, #4E5673, #0B2E42)',
            fontWeight: 'bold',
          }}
          bodyStyle={{ padding: 20 }}
          onClick={() => createOrder(API.create60Order)}
          loading={loading}
        >
          <div className="gradient-text">600,000 Token</div>
          <div className="gradient-text" style={{ fontSize: 30, marginTop: 10 }}>
            ￥29.00
          </div>
          <div style={{ marginTop: 10, color: "white" }}>
            <AlipayCircleFilled style={{ fontSize: 24 }} />
          </div>
      </Card>
      )}
      {!qrCode && !paid && (!isInBlacklist(user)) &&(
        <Card
          hoverable
          style={{
            width: 300,
            borderRadius: 10,
            margin: '100px auto',
            background: 'linear-gradient(to right, #038882, #004E4A)',
            fontWeight: 'bold',
          }}
          bodyStyle={{ padding: 20 }}
          onClick={() => createOrder(API.createSubOrder)}
          loading={loading}
        >
          <div className="gradient-text">GPT 3.5 - 包月无限流量</div>
          <div className="gradient-text" style={{ fontSize: 30, marginTop: 10 }}>
            ￥39.00
          </div>
          <div style={{ marginTop: 10, color: "white" }}>
            <AlipayCircleFilled style={{ fontSize: 24 }} />
          </div>
      </Card>
      )}
      {qrCode && !paid && (
        <div>
          <img src={`https://xorpay.com/qr?data=${qrCode}`} alt="qr-code" onClick={handleQrCodeClick}/>
          <div style={{ margin: '20px 0' }}>
            <Button type="primary" onClick={handleJumpToAlipay}>
              手机跳转支付宝
            </Button>
          </div>
          <div style={{ margin: '20px 0' }}>
            <Button type="primary" onClick={handlePaid}>
              我已付款
            </Button>
          </div>
        </div>
      )}
      {paid && (
        <div>
          <h2>购买成功！</h2>
        </div>
      )}
    </div>
  );
};

export default CharmingPay;