import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from "./Todo.module.css";
import "./index.css";
import { Hanko } from '@teamhanko/hanko-frontend-sdk';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MouseEventHandler } from "react";
import { User } from '@teamhanko/hanko-elements';

interface GradientButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}


const Button = styled.button`
  background-image: linear-gradient(135deg, #FE4F2D 0%, #0AA0ED 100%);
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 24px;
  text-decoration: none;
  transition: background-image 0.2s ease-in-out;
  width: 100%;
  max-width: 500px;
  min-width: 330px;

  &:hover {
    background-image: linear-gradient(135deg, #0AA0ED 0%, #FE4F2D 100%);
  }
`;

// define a component that renders markdown text as HTML, paramater is a click function
const GradientButton = ({onClick}: GradientButtonProps) => {
  return (
    <Button onClick={onClick}>
      去邀请
    </Button>
  );
};

// const GradientButton = ({onClick}) => {
//   return (
//     <Button onClick={onClick}>
//       去邀请
//     </Button>
//   );
// };

const api = process.env.REACT_APP_HANKO_API!
const hankoAPI = new Hanko(api)

export default GradientButton;

export const InviteCom = () => {

  // text hook
  const [pre_intro_text, setPre_intro_text] = React.useState(`
  ### 🎉 **2023-11-21 广播**： 现已支持最新版 GPT4 模型，上下文上限132,096，训练数据截止日期2023年4月，欢迎体验！
  ---
  
  ## 🌀 Charming 是一款
  - 基于官方正版 GPT4-32K 的聊天机器人
  - 免 API Key ，无需魔法，全球顺畅使用
  - 支持多国语音对话（包括方言）
  - 内置160+ 调教好的 prompts 预设，开箱即用
  - 支持与 Siri 的多轮对话
  - iCloud 同步，Mac/iPad/iPhone 三端跨平台

  \
  &nbsp;
  `)

  const [intro_text, setintro_text] = React.useState( `
  ## ✨  立即注册
  - 新用户见面礼：🪙Token **4000**
  - 每日 🪙Token **1000** 的免费聊天额度

  \
  &nbsp;
  \
  &nbsp;  

  ## 🎁 邀请好友
  - 每邀请一人，获得奖励 🪙Token **4000**
  - 被邀请人购买 🪙Token ，你将获得 **10%** 的返现
  
  *注：🪙Token 1000 大概支持 5~10 轮对话*
  `)

  //execute script when page is loaded
  // React.useEffect(() => {
  //   // use fetch to call a get api
  //   // fetch('https://api.github.com/repos/remarkjs/react-markdown')

  //   hankoAPI.user.getCurrent().then((user:any) => {
  //     const cookie = hankoAPI.token.client._getAuthCookie()
  //     console.log(`user:${JSON.stringify(user)}`);
  
  //     fetch('https://api.charming.chat/user', { //TODO: change to https://api.charming.chat    http://127.0.0.1:8787
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${cookie}`,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log("获取到用户信息:", data)
  //       setPre_intro_text(data.pre_intro_text)
  //       setintro_text(data.intro_text)
  //     })
  //     .catch(err1 => {
  //       toast.error(err1.message)
  //     });
  //   }).catch((err:any) => {
  //     toast.error(err.message);
  //   })
    
  // });

  return (
      <div className="content">
        <ReactMarkdown children={pre_intro_text} remarkPlugins={[remarkGfm]} />
        <ReactMarkdown children={intro_text} remarkPlugins={[remarkGfm]} />
      </div>
  );
};


export const Blacklist = (): string[] => {
  return ["707344813@qq.com"]
};

export const isInBlacklist = (user: User | null): boolean => {
  const blacklist = Blacklist();
  const email = user?.email;
  console.log(`检查黑名单：${email}, ${JSON.stringify(blacklist)}, ${JSON.stringify(user)}`)
  return blacklist.includes(email ?? '');
};